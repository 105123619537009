<template>
  <div class="row">
    <div class="col-lg-6">
      <UploadFileInput :disabled="loading || disabled" :name="name" :placeholder="placeholder" />
    </div>
    <div class="col-lg-3">
      <Spinner :loading="loading" />
      <div v-show="showSuccessMessage" class="upload alert alert-success ml-md-2 mr-md-auto text-center"><span>{{ $t('general.status.success') }}</span></div>
      <div v-show="showWarningMessage" class="upload alert alert-warning ml-md-2 mr-md-auto text-center"><span>{{ $t('general.status.warnings') }}</span></div>
    </div>
  </div>
</template>

<script>
import UploadFileInput from "@/components/elements/UploadFileInput";
import Spinner from "@/components/elements/Spinner";

export default {
  name: "UploadOrderFileInput",
  components: {Spinner, UploadFileInput},
  props: {
    name: {
      type: String,
    },
    placeholder: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    show_status: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    failed_products: {
      type: Number,
      default: 0
    },
    failed_orders: {
      type: Number,
      default: 0
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    showSuccessMessage: function() {
      return this.show_status && 0 == this.failed_orders && 0 == this.failed_products;
    },
    showWarningMessage: function() {
      return this.show_status && (this.failed_orders > 0 || this.failed_products > 0)
    }
  }
}
</script>

<style scoped>
.alert.upload {
  padding: .5rem 0rem
}
</style>