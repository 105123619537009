<template>
  <div class="row">
    <div class="col-md-12">
      <Card :title="'orders.upload.title'">
        <template v-slot:default>
          <Form>
            <template v-slot:default>
              <FormElement>
                <template v-slot:default>
                  <OrderCycleSelectInput :disabled="isLoading" :model-value="cycle && cycle.getId()" @selected="cycle = $event" class="col-lg-3" />
                </template>
              </FormElement>
              <FormElement>
                <template v-slot:default>
                  <UploadOrderFileInput :disabled="!cycle" @change="upload" :name="'orders'"
                                        :placeholder="'orders.upload.input.placeholder'" :show_status="isUploadComplete"
                                        :loading="isLoading" :failed_orders="meta.failed_orders"
                                        :failed_products="meta.failed_products"/>
                </template>
              </FormElement>
              <FormElement>
                <template v-slot:default>
                  <NotificationAlert :notifications="notification" />
                  <Alert v-show="meta.failed_products" type="warning">
                    <template v-slot:default>
                      {{ $t('orders.upload.modified', {nr: meta.failed_products})}}
                    </template>
                  </Alert>
                  <Alert v-show="meta.failed_orders" type="danger">
                    <template v-slot:default>
                      {{ $t('orders.upload.error', {nr: meta.failed_orders})}}
                    </template>
                  </Alert>
                </template>
              </FormElement>
              <FormElement>
                <template v-slot:default>
                  <div v-show="meta.orders">
                    <span class="d-block">{{ $t('orders.upload.count', {nr: meta.orders}) }}</span>
                    <span v-if="cycle">
                      {{ $t('orders.upload.delivery', {deliver_order: formatDay(cycle.getDeliverOrder().getDayOfWeek())}) }}
                    </span>
                  </div>
                </template>
              </FormElement>
            </template>
          </Form>
        </template>
        <template v-slot:footer>
          <div>
            <Button @click="onHideClick" v-bind:disabled="!isUploadComplete" name="orders.buttons.show_existing_orders" type="continue" />
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import FormElement from "@/components/elements/FormElement";
import Alert from "@/components/elements/Alert";
import UploadOrderFileInput from "@/components/order/backoffice/UploadOrderFileInput";
import Card from "@/components/elements/Card";
import Form from "@/components/elements/Form";
import Button from "@/components/elements/Button";
import {LoadingModalMixin} from "@/mixins/ModalMixin";
import {DatesMixin, ErrorHandlerMixin, NotificationsMixin} from "@/mixins/GeneralMixin";
import NotificationAlert from "@/components/elements/NotificationAlert";
import OrderCycleSelectInput from "@/components/order/backoffice/OrderCycleSelectInput";

export default {
  name: "OrderUpload",
  components: {OrderCycleSelectInput, NotificationAlert, Button, Card, UploadOrderFileInput, Alert, FormElement, Form},
  emits: ['update'],
  mixins: [LoadingModalMixin, NotificationsMixin, DatesMixin, ErrorHandlerMixin],
  data: function () {
    return {
      isUploadComplete: false,
      cycle: null,
      meta: {
        orders: 0,
        failed_products: 0,
        failed_orders: 0,
      }
    }
  },
  watch: {
    cycle: {
      handler: function () {
        this.reset();
      }
    }
  },
  methods: {
    reset: function () {
      this.meta.orders = 0;
      this.meta.failed_products = 0;
      this.meta.failed_orders = 0;
      this.isUploadComplete = false;
    },
    onHideClick()
    {
      this.reset();
      this.cycle = null;
      this.$emit('update');
      this.$router.push({name: "general.breadcrumb.orders.list"});
    },
    startUpload()
    {
      this.queueJob();
      this.resetNotifications();
      this.loading = true;
      this.isUploadComplete = false;
    },
    uploadComplete()
    {
      this.completeJob();
      this.isUploadComplete = true;
      this.$emit('update');
      this.$emit("uploaded");
    },

    upload(event) {
      let that = this;
      let data = new FormData();
      let file = event.target.files[0];
      let config = {
        header: {
          'Content-Type': 'multipart/form-data',
          'Authorization': this.authorization,
        }
      }

      if (null == file) {
        return;
      }

      this.startUpload();
      data.append('orders', file)

      this.axios.post(this.$store.state.config.getOrderUploadUri(this.cycle.getId()), data, config).then(
          response => {
            let content = response.data;

            this.meta.orders = content.data.length;
            if (null !== content.messages) {
              this.meta.failed_products = content.messages.warnings.length;
              this.meta.failed_orders = content.messages.errors.length;
            }
            that.uploadComplete();
            event.target.value=null;
          }
      ).catch(
          error => {
            console.warn(error);
            that.errorHandler(error);
            that.completeJob();
            event.target.value=null;
          }
      );
     },
  }
}
</script>

<style scoped>

</style>
