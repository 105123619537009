<template>
  <div class="form-group row">
    <label v-if="name" class="col-lg-3 col-form-label">{{ $t(name) }}</label>
    <div v-bind:class="name ? 'col-lg-9' : 'col-lg-12'">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormElement",
  props: {
    name: {
      type: String,
      default: "",
    }
  }
}
</script>

<style scoped>

</style>