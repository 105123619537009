<template>
  <div>
    <Select2 v-bind:disabled="disabled" v-model="cycle" :options="cycleOptions" :settings="cycleSelect2settings" v-bind:class="v$.cycle.$error && 'error'"/>
    <div class="input-errors" v-for="(error, index) of v$.cycle.$errors" :key="index">
      <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
    </div>
  </div>
</template>

<script>

import Cycle from "@/entities/Cycle";
import useVuelidate from "@vuelidate/core";
import { required } from '@vuelidate/validators'
import Select2 from 'vue3-select2-component';
import {LoadingModalMixin} from "@/mixins/ModalMixin";
import {ValidationsMixin} from "@/mixins/GeneralMixin";

export default {
  setup () {
    return { v$: useVuelidate() }
  },
  emits: ['update:modelValue', 'selected', 'loaded'],
  watch: {
    modelValue: {
      handler: function (value) {
        this.cycle = '' + value;
      }
    },
    cycle: {
      handler: function (value) {
        this.$nextTick(function () {
          this.v$.cycle.$touch();
          if (!this.v$.cycle.$error) {
            this.$emit('update:modelValue', value);
            this.$emit('selected', this.cycles[value]);
          }
        })
      }
    }
  },
  name: "OrderCycleSelectInput",
  components: {Select2},
  mixins: [LoadingModalMixin, ValidationsMixin],
  props: {
    modelValue: [String, Number],
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    onlyActive: {
      type: Boolean,
      default: false,
    }
  },
  validations: {
    cycle: {
      required,
    },
  },
  async beforeMount() {
    this.queueJob();
    this.cycleOptions = this.options.length === 0 ? await this.getCycles() : this.options;
    this.completeJob();
    this.$emit("loaded", this.cycleOptions)
  },
  data: function () {
    let that = this;
    return {
      cycle: "" + this.modelValue,
      cycleOptions: [],
      cycles: {},
      cycleSelect2settings: {
        placeholder: this.$i18n.t('orders.cycles'),
        width: '100%',
        dropdownAutoWidth: true,
        theme: 'bootstrap',
        language: {
          noResults: () => that.$i18n.t("general.select.no_results"),
        },
      },
    }
  },
  methods: {
    getCycles: function (pageNr = 1) {
      let that = this;
      let filterParams = {
        page: pageNr,
      };

      if (this.onlyActive) {
        filterParams.active = 1;
      }

      let options = this.axios.get(this.$store.state.config.getCyclesUri(), {params: filterParams}).then(
          response => {
            let options = [];
            let content = response.data;

            content.data.data.forEach(function (cycle) {
              cycle = new Cycle(cycle);
              that.cycles[cycle.getId()] = cycle;
              options.push({
                id: cycle.getId(),
                text: cycle.getName() + (cycle.isActive() ? " - " + that.$i18n.t('general.active').toLowerCase() : " - " + that.$i18n.t('general.inactive').toLowerCase()),
              });
            });

            return Promise.resolve(options);
          }
      ).catch(
          error => {
            console.warn(error);
            return Promise.resolve([]);
          }
      );
      return options;
    }
  }
}
</script>

<style scoped>

</style>