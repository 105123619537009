<template>
  <form v-bind:action="action" v-bind:method="method">
    <slot></slot>
  </form>
</template>

<script>
export default {
  name: "Form",
  props: {
    action: {
      type: String,
      default: "#"
    },
    method: {
      type: String,
      default: "POST",
    }
  }
}
</script>

<style scoped>

</style>